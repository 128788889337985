export var calculateBodyLength = function (body) {
    if (typeof body === "string") {
        var len = body.length;
        for (var i = len - 1; i >= 0; i--) {
            var code = body.charCodeAt(i);
            if (code > 0x7f && code <= 0x7ff)
                len++;
            else if (code > 0x7ff && code <= 0xffff)
                len += 2;
            if (code >= 0xdc00 && code <= 0xdfff)
                i--;
        }
        return len;
    }
    else if (typeof body.byteLength === "number") {
        return body.byteLength;
    }
    else if (typeof body.size === "number") {
        return body.size;
    }
    throw new Error("Body Length computation failed for ".concat(body));
};
